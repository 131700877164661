<template>
  <div class="wow-grid-honoree-listing">
    <div v-show="showDefaultData" class="wow-grid-honoree-listing__wrapper">
      <slot />
    </div>
    <transition-group
      v-show="!showDefaultData"
      class="wow-grid-honoree-listing__wrapper"
      name="grid-list"
      tag="div"
    >
      <HonoreeItem
        v-for="honoree in honorees"
        :key="honoree.id"
        :covid-nominee="honoree.covidNominee"
        :honoree-profile="honoree.honoreeProfile"
        :honoree-profile-label="honoreeProfileLabel"
        :image="honoree.image"
        :name="honoree.name"
        :national-nominee="honoree.nationalNominee"
        :organization="honoree.organization"
        :state="honoree.state"
        :year="honoree.year"
      />
    </transition-group>
  </div>
</template>

<script>
import HonoreeItem from './honoreeItem.vue';
import {
  WOW_HONOREE_LISTING_GET_HONOREES,
  WOW_HONOREE_LISTING_COMPONENT_GET_SHOW_SLOT_DATA,
} from '../../settings';
import { mapGetters } from '../../helpers';

export default {
  name: 'HonoreeGrid',

  components: {
    HonoreeItem,
  },

  props: {
    fetchData: {
      type: Boolean,
      required: false,
    },
    honoreeCovidNomineeLabel: {
      default: 'Covid-19',
      required: false,
      type: String,
    },
    honoreeNationalNomineeLabel: {
      default: 'National nominee',
      required: false,
      type: String,
    },
    honoreeProfileLabel: {
      default: 'Learn More',
      required: false,
      type: String,
    },
  },

  computed: {
    ...mapGetters([
      WOW_HONOREE_LISTING_COMPONENT_GET_SHOW_SLOT_DATA,
      WOW_HONOREE_LISTING_GET_HONOREES,
    ]),

    honorees() {
      return this[WOW_HONOREE_LISTING_GET_HONOREES];
    },

    showDefaultData() {
      return this[WOW_HONOREE_LISTING_COMPONENT_GET_SHOW_SLOT_DATA];
    },
  },
};
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter,
.list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
