<template>
  <section class="honoree-form">
    <h2 class="honoree-form__title">
      {{ translations.title }}
    </h2>
    <form ref="form" @submit="handleFormSubmit">
      <fieldset class="honoree-form__subsection row">
        <legend class="honoree-form__instructions col-lg-12">
          {{ translations.openEndedQuestionsSection.subtitle }}
        </legend>
      </fieldset>

      <hr class="honoree-form__hr" />

      <fieldset class="honoree-form__subsection">
        <legend class="honoree-form__subsection__title honoree-form__subsection__title">
          {{ translations.openEndedQuestionsSection.fields.describeNomineeWork.title }}
        </legend>
        <HonoreeFormTextAreaField
          id="description-of-nominee-work"
          v-model="formData.volunteerWorkInfo.workOverview"
          :label="translations.openEndedQuestionsSection.fields.describeNomineeWork.label"
          :is-required="true"
          :initial-data="initialData.data.nomineeInformation.nomineeService"
          :character-counter="
            translations.openEndedQuestionsSection.fields.describeNomineeWork.helpText
          "
          :error-message="
            translations.openEndedQuestionsSection.fields.describeNomineeWork.requiredFieldMessage
          "
        />
      </fieldset>

      <hr class="honoree-form__hr" />

      <fieldset class="honoree-form__subsection">
        <legend class="honoree-form__subsection__title honoree-form__subsection__title">
          {{ translations.openEndedQuestionsSection.fields.communityNeedsAndImpact.title }}
        </legend>
        <HonoreeFormTextAreaField
          id="community-needs-and-impact"
          v-model="formData.volunteerWorkInfo.communityNeeds"
          :label="translations.openEndedQuestionsSection.fields.communityNeedsAndImpact.label"
          :is-required="true"
          :initial-data="initialData.data.nomineeInformation.communityNeeds"
          :character-counter="
            translations.openEndedQuestionsSection.fields.communityNeedsAndImpact.helpText
          "
          :error-message="
            translations.openEndedQuestionsSection.fields.communityNeedsAndImpact
              .requiredFieldMessage
          "
        />
      </fieldset>

      <hr class="honoree-form__hr" />

      <fieldset class="honoree-form__subsection">
        <legend class="honoree-form__subsection__title honoree-form__subsection__title">
          {{ translations.openEndedQuestionsSection.fields.nomineeSummary.title }}
        </legend>
        <HonoreeFormTextAreaField
          id="nominee-summary"
          v-model="formData.volunteerWorkInfo.nomineeSummary"
          :label="translations.openEndedQuestionsSection.fields.nomineeSummary.label"
          :is-required="true"
          :initial-data="initialData.data.nomineeInformation.nomineeSummary"
          :character-counter="translations.openEndedQuestionsSection.fields.nomineeSummary.helpText"
          :error-message="
            translations.openEndedQuestionsSection.fields.nomineeSummary.requiredFieldMessage
          "
        />
      </fieldset>

      <hr class="honoree-form__hr" />

      <fieldset class="honoree-form__subsection">
        <legend class="honoree-form__subsection__title honoree-form__subsection__title">
          {{ translations.categoriesOfWork.title }}
        </legend>
        <span
          class="honoree-form__subsection__subtitle honoree-form__subsection__subtitle--paragraph"
          v-html="translations.categoriesOfWork.paragraph"
        ></span>
        <HonoreeFormCheckboxField
          id="categories-of-work-checkboxes"
          v-model="formData.volunteerWorkInfo.category"
          :model-value="formData.volunteerWorkInfo.category"
          :options="translations.categoriesOfWork.fields.options"
          :initial-data="initialData.data.nomineeInformation.categoriesOfWork"
          :error-message="translations.categoriesOfWork.fields.requiredFieldMessage"
          :maximum="3"
          :minimum="1"
          @update:modelValue="(newValue) => (formData.volunteerWorkInfo.category = newValue)"
        />
      </fieldset>
      <hr class="honoree-form__hr" />

      <fieldset class="honoree-form__subsection">
        <legend class="wow-nomination-form-legend-hide"></legend>
        <HonoreeFormTextAreaField
          id="nominee-charitableSection"
          v-model="formData.volunteerWorkInfo.charitableSection"
          :label="translations.openEndedQuestionsSection.fields.charitableSection.title"
          :is-required="true"
          :initial-data="initialData.data.nomineeInformation.charitableSection"
          :character-counter="
            translations.openEndedQuestionsSection.fields.charitableSection.helpText
          "
          :error-message="
            translations.openEndedQuestionsSection.fields.charitableSection.requiredFieldMessage
          "
        />
      </fieldset>
      <hr class="honoree-form__hr" />

      <div class="honoree-form__buttons-wrapper">
        <button
          class="wow-button wow-button--black"
          type="button"
          @click="handleFormSave"
          v-text="formLabels.saveButton.label"
        />
        <button
          class="wow-button wow-button--black"
          @click="triggerValidation"
          v-text="`${formLabels.nextButton.label} >`"
        />
      </div>
      <div>
        <button
          class="wow-button"
          type="button"
          @click.prevent="goToPreviousPage"
          v-text="`< ${formLabels.previousButton.label}`"
        />
      </div>
    </form>
  </section>
</template>

<script>
import { nextTick } from 'vue';
import eventBus from '@loreal/eventbus-js';
import {
  PERSONAL_PAGE,
  QUESTIONS_PAGE,
  axiosPostWrapper,
  VALIDATE_CHILD_EVENT,
  SURVEY_PAGE,
} from './settings';

import HonoreeFormTextAreaField from './HonoreeFormTextAreaField.vue';
import HonoreeFormCheckboxField from './HonoreeFormCheckboxField.vue';

export default {
  name: 'HonoreeFormQuestionsPage',
  components: {
    HonoreeFormTextAreaField,
    // HonoreeFormInputField,
    HonoreeFormCheckboxField,
    // HonoreeFormSelectField,
  },
  props: {
    allowedSteps: {
      type: Number,
    },
    settings: {
      type: Object,
    },
    translations: {
      type: Object,
    },
    formLabels: {
      type: Object,
    },
    formId: {
      type: String,
    },
    initialData: {
      type: Object,
    },
  },
  data() {
    return {
      formData: {
        organizationInfo: {
          serviceWithOrg: this.initialData.data.isOrganizationService,
          name: '',
          role: '',
          phone: '',
          city: '',
          state: '',
          website: '',
          facebookLink: '',
          mission: '',
        },
        volunteerWorkInfo: {
          workOverview: '',
          communityNeeds: '',
          nomineeSummary: '',
          category: [],
          charitableSection: '',
        },
      },
      isFormValid: false,
      step: QUESTIONS_PAGE,
    };
  },
  methods: {
    triggerValidation() {
      eventBus.emit(VALIDATE_CHILD_EVENT);
    },
    handleFormSubmit(e) {
      eventBus.emit(VALIDATE_CHILD_EVENT);

      nextTick().then(() => {
        // const formValid = this.validateObject(this.formData.organizationInfo) && this.validateObject(this.formData.volunteerWorkInfo);
        const formValid = this.validateObject(this.formData.volunteerWorkInfo);
        const data = this.gatherData(true);
        if (formValid) this.submitData(data);
      });
      e.preventDefault();
    },
    handleFormSave() {
      eventBus.emit(VALIDATE_CHILD_EVENT);

      nextTick().then(() => {
        const data = this.gatherData();
        this.submitData(data);
      });
    },

    validateObject(object) {
      const keys = Object.keys(object);
      let formValid = true;
      keys.forEach((key) => {
        if (typeof object[key] === 'object' && !object[key].isValid) {
          formValid = false;
        }
      });

      return formValid;
    },
    submitData(data) {
      axiosPostWrapper(this.settings.webserviceUrl, JSON.stringify(data)).then((response) => {
        if (data.incrementLocalStep) this.$emit('nextClick', SURVEY_PAGE);
        this.$emit('onDataSubmit', response);
      });
    },
    extractData(object) {
      const keys = Object.keys(object);
      const data = {};
      keys.forEach((key) => {
        if (typeof object[key] === 'object') {
          data[key] = object[key].value;
        } else {
          data[key] = object[key];
        }
      });
      return data;
    },
    gatherData(incrementLocalStep = false) {
      const incrementAllowedStep = () => {
        return incrementLocalStep && this.step >= this.allowedSteps;
      };

      return {
        nominationId: this.formId,
        step: this.step,
        incrementLocalStep,
        incrementStep: incrementAllowedStep(),
        organizationInfo: this.extractData(this.formData.organizationInfo),
        volunteerWorkInfo: this.extractData(this.formData.volunteerWorkInfo),
      };
    },
    goToPreviousPage() {
      this.$emit('previousClick', PERSONAL_PAGE);
    },
  },
};
</script>
