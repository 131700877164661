import {
  WOW_HONOREE_LISTING_GET_CURRENT_PAGE,
  WOW_HONOREE_LISTING_GET_PAGE_NUMBER,
  WOW_HONOREE_LISTING_GET_HONOREES,
  WOW_HONOREE_LISTING_GET_FILTERS,
  WOW_HONOREE_LISTING_GET_FILTER,
  WOW_HONOREE_LISTING_COMPONENT_GET_SHOW_SLOT_DATA,
  WOW_HONOREE_GET_VOTING_MODAL,
  WOW_HONOREE_GET_HONOREE_DATA,
  WOW_HONOREE_VOTING_BANNER_OPEN,
} from '../settings';

export default {
  [WOW_HONOREE_LISTING_GET_CURRENT_PAGE]: (state) => {
    return state.honoreeListing.currentPage;
  },
  [WOW_HONOREE_LISTING_GET_HONOREES]: (state) => {
    return state.honoreeListing.honorees;
  },
  [WOW_HONOREE_LISTING_GET_PAGE_NUMBER]: (state) => {
    return state.honoreeListing.pageNumber;
  },
  [WOW_HONOREE_LISTING_GET_FILTER]: (state) => {
    let obj = {};
    state.honoreeListing.filters.forEach((item) => {
      obj[item.filterName] = item.options
        .filter((o) => o.selected)
        .map((o) => o.value)
        .concat();
    });

    obj.page = state.honoreeListing.currentPage;
    return obj;
  },
  [WOW_HONOREE_LISTING_GET_FILTERS]: (state) => {
    return state.honoreeListing.filters;
  },
  [WOW_HONOREE_LISTING_COMPONENT_GET_SHOW_SLOT_DATA]: (state) => {
    return state.honoreeListing.showSlotData;
  },
  [WOW_HONOREE_GET_VOTING_MODAL]: (state) => {
    return state.honoreeVotingModal;
  },
  [WOW_HONOREE_GET_HONOREE_DATA]: (state) => {
    return state.honoreeData;
  },
  [WOW_HONOREE_VOTING_BANNER_OPEN]: (state) => {
    return state.votingBannerOpen;
  },
};
