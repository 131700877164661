<template>
  <div v-if="showBanner" class="wow-vote-submitted-banner">
    <div class="wow-vote-submitted-banner__container">
      <div class="wow-vote-submitted-banner__inner">
        <h2 class="wow-vote-submitted-banner__title">
          <slot name="thankYouMessage"></slot>
          {{ honoreeName }}
        </h2>
        <p class="wow-vote-submitted-banner__info-message">
          <slot name="comeBackMessage"></slot>
        </p>
        <button
          class="wow-expand-btn"
          :class="{ 'wow-vote-submitted-banner__info-message--active': !isActive }"
          @click="buttonModal"
        ></button>
      </div>
      <div class="wow-vote-submitted-banner__info-area">
        <p
          class="wow-vote-submitted-banner__info-message"
          :class="{ 'wow-vote-submitted-banner__info-message--active': isActive }"
        >
          <slot name="comeBackMessage"></slot>
        </p>
        <div
          class="wow-vote-submitted-banner__share-area"
          :class="{ 'wow-vote-submitted-banner__info-message--active': isActive }"
        >
          <p>
            <slot name="socialSharingLinks"></slot>
          </p>
          <a
            class="wow-voted-banner__share share-link"
            :href="facebookShareUrl"
            rel="noopener noreferrer"
            target="_blank"
            @click="sendEvent('Facebook')"
          >
            <svg
              width="9px"
              height="17px"
              viewBox="0 0 9 17"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <!-- Generator: Sketch 51.1 (57501) - http://www.bohemiancoding.com/sketch -->
              <title>Facebook</title>
              <desc>Created with Sketch.</desc>
              <defs></defs>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g
                  id="d_footer_02_1200px"
                  transform="translate(-429.000000, -212.000000)"
                  fill="#FFFFFF"
                >
                  <g id="social" transform="translate(421.000000, 118.000000)">
                    <g id="Facebook" transform="translate(8.000000, 94.000000)">
                      <path
                        id="Fill-8"
                        d="M8.88539554,5.73409686 L5.96352568,5.73409686 L5.96352568,4.10471049 C5.96352568,3.49319624 6.44032833,3.35032832 6.77583458,3.35032832 L8.83771527,3.35032832 L8.83771527,0.660555491 L5.99783222,0.651162791 C2.84570157,0.651162791 2.12817174,2.65724589 2.12817174,3.94107975 L2.12817174,5.73409686 L0.305273834,5.73409686 L0.305273834,8.50543788 L2.12817174,8.50543788 L2.12817174,16.3488372 L5.96352568,16.3488372 L5.96352568,8.50543788 L8.55047075,8.50543788 L8.88539554,5.73409686 Z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </a>
          <a
            class="wow-voted-banner__share share-link"
            :href="twitterShareUrl"
            rel="noopener noreferrer"
            target="_blank"
            @click="sendEvent('Twitter')"
          >
            <img src="/frontend/static/images/twitter.svg" alt="twitter" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW_HONOREE_VOTING_BANNER_OPEN, WOW_HONOREE_SET_VOTING_BANNER_OPEN } from '../../settings';
import { mapGetters, mapActions } from '../../helpers';

export default {
  name: 'HonoreeVotingBanner',
  props: {
    twitterShareBaseUrl: {
      type: String,
      default: '',
    },
    facebookShareBaseUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    ...mapGetters([WOW_HONOREE_VOTING_BANNER_OPEN]),
    honoreeName() {
      return this.getCookie('wow_voted_name');
    },
    votedProfileLink() {
      return this.getCookie('wow_voted_profile_url');
    },
    showBanner() {
      return this[WOW_HONOREE_VOTING_BANNER_OPEN];
    },
    twitterShareUrl() {
      return `${this.twitterShareBaseUrl}text=${this.honoreeName}&url=${this.votedProfileLink}`;
    },
    facebookShareUrl() {
      return `${this.facebookShareBaseUrl}u=${this.votedProfileLink}`;
    },
  },
  created() {
    if (this.getCookie('wow_voted_name')) {
      this[WOW_HONOREE_SET_VOTING_BANNER_OPEN](true);
    }
  },
  methods: {
    ...mapActions([WOW_HONOREE_SET_VOTING_BANNER_OPEN]),
    buttonModal() {
      this.isActive = !this.isActive;
    },
    getCookie(cname) {
      let name = cname + '=';
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },
    sendEvent(socialNetwork) {
      const tag = {
        event: 'e_social',
        socialAction: 'Share',
        socialNetwork,
        socialTarget: `WOW:${this.honoreeName}`,
      };

      try {
        window.dataLayer.push(tag);
      } catch (e) {
        console.warn('Could not push to dataLayer', tag);
      }
    },
  },
};
</script>
