<template>
  <div class="honoree-form__select-group">
    <label :id="`${id}--label`" class="honoree-form__sr-only" :for="id">{{ label }}</label>
    <select
      :id="id"
      ref="input"
      v-model="data"
      class="honoree-form__select__input"
      @change="updateValue"
    >
      <option disabled :value="null">{{ defaultOption }}</option>
      <option v-for="(option, $key) in options" :key="$key" :value="option.id">
        {{ option.label }}
      </option>
    </select>
    <span v-if="showErrorMessages" :id="`${id}--error`" class="honoree-form__error-state">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import HonoreeFieldMixins from './HonoreeFieldMixins';

export default {
  name: 'HonoreeFormSelectField',
  mixins: [HonoreeFieldMixins],
  props: {
    options: {
      type: [Array, String],
      required: true,
    },
    defaultOption: {
      type: String,
      default: 'Select',
    },
  },
  emits: ['update:modelValue', 'input'],
  data() {
    return {
      data: this.initialData === '' ? null : this.initialData,
      pristine: true,
    };
  },
  computed: {
    isValid() {
      return this.isRequired ? this.data !== null : true;
    },
  },
  methods: {
    updateValue() {
      this.pristine = false;
      nextTick(() => {
        this.$emit('input', this.data);
        this.$emit('update:modelValue', this.selectedOption);
      });
    },
  },
};
</script>
