import {
  // WOW_HONOREE_LISTING_UPDATE_FILTER,
  WOW_HONOREE_LISTING_UPDATE_CURRENT_PAGE,
  WOW_HONOREE_LISTING_UPDATE_PAGE_NUMBER,
  WOW_HONOREE_LISTING_UPDATE_HONOREES,
  WOW_HONOREE_LISTING_ENABLE_LOADING_STATE,
  WOW_HONOREE_LISTING_DISABLE_LOADING_STATE,
  WOW_HONOREE_LISTING_REGISTER_FILTERS,
  WOW_HONOREE_LISTING_COMPONENT_UPDATE_SHOW_SLOT_DATA,
  WOW_HONOREE_TOGGLE_VOTING_MODAL,
  WOW_HONOREE_UPDATE_HONOREE_DATA,
  WOW_HONOREE_SET_VOTING_BANNER_OPEN,
} from '../settings';

import { honoreeDataTransform, filterDataTransform } from '../utils/dataTransform';

export default {
  [WOW_HONOREE_LISTING_ENABLE_LOADING_STATE]: (state) => {
    state.honoreeListing.loading = true;
  },
  [WOW_HONOREE_LISTING_ENABLE_LOADING_STATE]: (state) => {
    state.honoreeListing.loading = true;
  },
  [WOW_HONOREE_LISTING_DISABLE_LOADING_STATE]: (state) => {
    state.honoreeListing.loading = false;
  },
  [WOW_HONOREE_LISTING_UPDATE_HONOREES]: (state, honoreesData) => {
    state.honoreeListing.honorees = honoreesData.map((honoree) => honoreeDataTransform(honoree));
  },
  [WOW_HONOREE_LISTING_UPDATE_PAGE_NUMBER]: (state, pages) => {
    state.honoreeListing.pageNumber = pages;
  },
  [WOW_HONOREE_LISTING_UPDATE_CURRENT_PAGE]: (state, current) => {
    state.honoreeListing.currentPage = current;
  },
  [WOW_HONOREE_LISTING_REGISTER_FILTERS]: (state, filtersData) => {
    const filters = filtersData.map((filter) => {
      return filterDataTransform(filter);
    });
    state.honoreeListing.filters = filters;
  },
  [WOW_HONOREE_LISTING_COMPONENT_UPDATE_SHOW_SLOT_DATA]: (state) => {
    state.honoreeListing.showSlotData = false;
  },
  [WOW_HONOREE_TOGGLE_VOTING_MODAL]: (state, value) => {
    state.honoreeVotingModal = value;
  },
  [WOW_HONOREE_UPDATE_HONOREE_DATA]: (state, value) => {
    state.honoreeData = value;
  },
  [WOW_HONOREE_SET_VOTING_BANNER_OPEN]: (state, value) => {
    state.votingBannerOpen = value;
  },
};
