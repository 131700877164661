<template>
  <button class="honoree-voting-button wow-cta" @click="openModal()">
    <slot name="openModalBtnLabel"></slot>
  </button>
</template>

<script>
import { mapActions } from '../../helpers';
import {
  WOW_HONOREE_TOGGLE_VOTING_MODAL_ACTION,
  WOW_HONOREE_SEND_HONOREE_DATA,
} from '../../settings';

const votingButton = 'votingButton';

export default {
  name: votingButton,
  props: {
    honoreeName: {
      type: String,
      default: '',
    },
    honoreeId: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapActions([WOW_HONOREE_TOGGLE_VOTING_MODAL_ACTION, WOW_HONOREE_SEND_HONOREE_DATA]),
    openModal() {
      if (this.getCookie('wow_voted_name')) {
        this.scrollTop();
      } else {
        this[WOW_HONOREE_SEND_HONOREE_DATA]({
          honoreeName: this.honoreeName,
          honoreeId: this.honoreeId,
          email: this.email,
        });
        this[WOW_HONOREE_TOGGLE_VOTING_MODAL_ACTION](true);
      }
    },
    closeModal() {
      this.modalOpen = false;
    },
    scrollTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    getCookie(cname) {
      let name = cname + '=';
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },
  },
};
</script>
