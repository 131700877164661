import HonoreeFilter from './code/Scripts/components/HonoreeListingComponent/honoreeFilter.vue';
import HonoreeFilterItem from './code/Scripts/components/HonoreeListingComponent/honoreeFilterItem.vue';
import HonoreeGrid from './code/Scripts/components/HonoreeListingComponent/honoreeGrid.vue';
import HonoreeItem from './code/Scripts/components/HonoreeListingComponent/honoreeItem.vue';
import HonoreePagination from './code/Scripts/components/HonoreeListingComponent/honoreePagination.vue';
import HonoreePaginationItem from './code/Scripts/components/HonoreeListingComponent/honoreePaginationItem.vue';
import HonoreeListingComponent from './code/Scripts/components/HonoreeListingComponent/honoreeListingComponent.vue';
import HonoreeVotingWrapper from './code/Scripts/components/HonoreeVoting/honoreeVotingWrapper.vue';
import HonoreeVotingBanner from './code/Scripts/components/HonoreeVoting/honoreeVotingBanner.vue';
import HonoreeVotingButton from './code/Scripts/components/HonoreeVoting/honoreeVotingButton.vue';

import DropdownComponent from './code/Scripts/components/DropdownComponent/dropdownComponent.vue';

import HonoreeNominationForm from './code/Scripts/components/HonoreeNominationComponent/HonoreeNominationForm.vue';

import { WOW_HONOREE_LISTING_COMPONENT_NAMESPACE } from './code/Scripts/settings';
import wowHonoreeListingComponentStore from './code/Scripts/store';

export default {
  components: {
    HonoreeFilter,
    HonoreeFilterItem,
    HonoreeGrid,
    HonoreeItem,
    HonoreePagination,
    HonoreePaginationItem,
    HonoreeListingComponent,
    HonoreeVotingWrapper,
    DropdownComponent,
    HonoreeVotingBanner,
    HonoreeVotingButton,
    HonoreeNominationForm,
  },
  modules: {
    [WOW_HONOREE_LISTING_COMPONENT_NAMESPACE]: wowHonoreeListingComponentStore,
  },
};
