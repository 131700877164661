<template>
  <div class="wow-honoree-filter">
    <HonoreeFilterItem
      v-for="filter in filters"
      :key="filter.id"
      :options="filter.options"
      :filter-name="filter.filterName"
      :filter-title="filter.label"
    />
  </div>
</template>

<script>
import HonoreeFilterItem from './honoreeFilterItem.vue';
import { WOW_HONOREE_LISTING_GET_FILTERS } from '../../settings';
import { mapGetters } from '../../helpers';

export default {
  name: 'HonoreeFilter',
  components: {
    HonoreeFilterItem,
  },
  computed: {
    ...mapGetters([WOW_HONOREE_LISTING_GET_FILTERS]),
    filters() {
      return this[WOW_HONOREE_LISTING_GET_FILTERS];
    },
  },
};
</script>
