<template>
  <div class="wow-honoree-voting-wrapper">
    <OapPopup
      title="Channel Advisor"
      :open="votingModalOpen"
      :hide-overflow="true"
      :aside="false"
      @closed="closeModal"
    >
      <h3 class="wow-popup__title h3-wow">
        <slot name="complete-vote-for" />
        {{ honoreeData.honoreeName }}
      </h3>
      <span class="wow-popup__subtitle">
        <slot name="subtitle" />
      </span>
      <form id="votingForm" class="wow-popup-form container-form" @submit="voteToHonoree">
        <div class="wow-popup-form__email-field voting-email">
          <label for="honoree-voting-wrapper__email-input">
            <slot name="email-field-label" />
            <input
              id="honoree-voting-wrapper__email-input"
              v-model="emailInput"
              aria-describedby="honoree-voting-wrapper__email-input__error"
              :class="{ 'modal-error': emailError }"
              type="email"
              pattern="[\w.-]+@[\w-]+\.[\w.-]+"
            />
          </label>
          <div id="honoree-voting-wrapper__email-input__error" class="modal-error-checkbox">
            <slot v-if="emailError" name="email-field-error-label" />
          </div>
        </div>
        <label
          class="wow-popup-form__checkbox cheModal wow-popup-form__checkbox-opt-in cheModalModal1"
          for="optinCheckbox"
        >
          <input id="optinCheckbox" type="checkbox" />
          <p><slot name="optin-checkbox" /></p>
        </label>
        <label
          class="wow-popup-form__checkbox wow-popup-form__checkbox-age"
          for="wow-popup-form__checkbox-age"
        >
          <input id="wow-popup-form__checkbox-age" v-model="checkbox" type="checkbox" />
          <p><slot name="age-confirmation-checkbox" /></p>
        </label>
        <div class="modal-error-checkbox">
          <slot v-if="erroCheckBox" name="age-confirmation-checkbox-error" />
        </div>
        <button class="wow-popup-form__submit-btn voting-btn" form="votingForm">
          <slot name="voting-button-title" />
        </button>
      </form>
      <template #close>
        <button class="wow-popup__close-btn channel-advisor-modal__exit" @click="closeModal">
          <span class="is-sr-only">close</span>
          <svg class="icon" aria-hidden="true">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
          </svg>
        </button>
      </template>
    </OapPopup>
  </div>
</template>

<script>
import OapPopup from '../../../../../OapPopup/code/Scripts/components/OapPopup.vue';
import {
  WOW_HONOREE_GET_VOTING_MODAL,
  WOW_HONOREE_TOGGLE_VOTING_MODAL_ACTION,
  WOW_HONOREE_GET_HONOREE_DATA,
  WOW_HONOREE_SET_VOTING_BANNER_OPEN,
} from '../../settings';
import { mapGetters, mapActions } from '../../helpers';
import { AnalyticsHandler } from '../../../../../../Foundation/Core/code/Scripts/analyticsHandler';

export default {
  name: 'HonoreeVotingWrapper',

  components: {
    OapPopup,
  },

  props: {
    apiFetchUrl: {
      default: 'https://qa-aws.lorealparisusa.com/LorealParisUS/services/ajax/honoreeVote.ashx',
      type: String,
    },
  },

  data() {
    return {
      erroModal: false,
      emailError: false,
      checkbox: false,
      erroCheckBox: false,
      bannerModal: false,
      emailInput: '',
    };
  },

  computed: {
    ...mapGetters([WOW_HONOREE_GET_VOTING_MODAL, WOW_HONOREE_GET_HONOREE_DATA]),

    honoreeData() {
      return this[WOW_HONOREE_GET_HONOREE_DATA];
    },

    votingModalOpen() {
      return this[WOW_HONOREE_GET_VOTING_MODAL];
    },
  },

  methods: {
    ...mapActions([WOW_HONOREE_TOGGLE_VOTING_MODAL_ACTION, WOW_HONOREE_SET_VOTING_BANNER_OPEN]),

    closeModal() {
      this[WOW_HONOREE_TOGGLE_VOTING_MODAL_ACTION](false);
      this.emailInput = '';
      this.checkbox = false;
    },

    voteToHonoree(event) {
      event.preventDefault();
      if (this.emailInput.length === 0) {
        this.emailError = true;
      } else if (this.checkbox === false) {
        this.erroCheckBox = true;
        this.emailError = false;
      } else {
        this.emailError = false;
        this.erroCheckBox = false;
        this.callVotingApi();
        this.closeModal();
      }
    },

    callVotingApi() {
      const headers = {
        'content-type': 'application/json',
      };
      const body = JSON.stringify({
        email: this.emailInput,
        honoreeid: this.honoreeData.honoreeId,
      });
      fetch(this.apiFetchUrl, {
        method: 'POST',
        headers,
        body,
      })
        .then((res) => res.json())
        .then(() => {
          this.handleVotingAnalytics();
          this.setVotingCookies();
          this[WOW_HONOREE_SET_VOTING_BANNER_OPEN](true);
        });
    },

    setVotingCookies() {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      const fixedDate = date.toUTCString();
      const honoreeName = this.honoreeData.honoreeName;
      document.cookie = `wow_voted_name=${honoreeName}; expires=${fixedDate}`;
      document.cookie = `wow_voted_profile_url=https://${window.location.host}/women-of-worth/honorees/2020/${this.honoreeData.honoreeId}; expires=${fixedDate}`;
    },

    handleVotingAnalytics() {
      AnalyticsHandler.getAnalyticsHandler().push({
        action: 'Send Vote',
        category: 'women of worth',
        label: this.honoreeData.honoreeName,
        type: 'userActionEvent',
      });
    },
  },
};
</script>
