<template>
  <div class="wow-nomination-submission-review">
    <div class="wow-nomination-submission-review__headline">
      <h1 class="wow-nomination-submission-review__headline__title">
        {{ overviewPage.title }}
      </h1>
      <p class="wow-nomination-submission-review__headline__description">
        {{ overviewPage.subTitle }}
      </p>
    </div>

    <section class="wow-nomination-submission-review__section">
      <div class="section__title">
        <h3 class="section__title__heading">
          {{ overviewPage.contentSections.eligibilityAndContactInfo.title }}
        </h3>

        <button class="wow-button" @click="goToPage(1)">
          {{ translations.formLabels.editButton.label }}
        </button>
      </div>

      <dl class="wow-nomination-submission-review__list">
        <dt class="wow-nomination-submission-review__list__item__title">
          {{
            overviewPage.contentSections.eligibilityAndContactInfo.mainSection.fields.compensation
              .label
          }}:
        </dt>
        <dd class="wow-nomination-submission-review__list__item__value">
          {{ getCompensation() }}
        </dd>

        <dt class="wow-nomination-submission-review__list__item__title">
          {{
            overviewPage.contentSections.eligibilityAndContactInfo.mainSection.fields
              .activelyVolunteering.label
          }}:
        </dt>
        <dd class="wow-nomination-submission-review__list__item__value">
          {{ isNomineeCurrentlyWorking() }}
        </dd>

        <dt class="wow-nomination-submission-review__list__item__title">
          {{
            overviewPage.contentSections.eligibilityAndContactInfo.mainSection.fields
              .totalVolunteerHours.label
          }}:
        </dt>
        <dd class="wow-nomination-submission-review__list__item__value">
          {{ getServiceDuration() }}
        </dd>

        <dt class="wow-nomination-submission-review__list__item__title">
          {{
            overviewPage.contentSections.eligibilityAndContactInfo.mainSection.fields.dataStarted
              .label
          }}:
        </dt>
        <dd class="wow-nomination-submission-review__list__item__value">
          {{ getServiceBegin() }}
        </dd>
      </dl>

      <div class="section__title">
        <h3 class="section__title__heading">
          {{ overviewPage.contentSections.eligibilityAndContactInfo.contactInfoSection.title }}
        </h3>
      </div>

      <dl class="wow-nomination-submission-review__list">
        <dt class="wow-nomination-submission-review__list__item__title">
          {{
            overviewPage.contentSections.eligibilityAndContactInfo.contactInfoSection.fields
              .selfNomination.label
          }}:
        </dt>
        <dd class="wow-nomination-submission-review__list__item__value">
          {{ isNominatingYourself() }}
        </dd>
      </dl>

      <p
        v-if="!initialData.steps.eligibilityAndContactInfoPage.data.nominatingYourself"
        class="honoree-form__subsection__title honoree-form__subsection__title"
      >
        {{ eligibilityAndContactInfo.fieldSections.yourContactInfoSection.title }}
      </p>
      <p v-if="!initialData.steps.eligibilityAndContactInfoPage.data.nominatingYourself">
        <span v-text="userContactInfo" /> <br />
        {{ initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.jobTitle }}
        {{ initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.organizationName }}
      </p>

      <dl
        v-if="!initialData.steps.eligibilityAndContactInfoPage.data.nominatingYourself"
        class="wow-nomination-submission-review__list"
      >
        <dt
          v-if="initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.primaryPhone"
          class="wow-nomination-submission-review__list__item__title"
        >
          {{
            eligibilityAndContactInfo.fieldSections.yourContactInfoSection.fields.primaryPhone
              .label
          }}:
        </dt>
        <dd
          v-if="initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.primaryPhone"
          class="wow-nomination-submission-review__list__item__value"
        >
          {{ initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.primaryPhone }}
        </dd>

        <dt
          v-if="initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.workPhone"
          class="wow-nomination-submission-review__list__item__title"
        >
          {{
            eligibilityAndContactInfo.fieldSections.yourContactInfoSection.fields.workPhone.label
          }}:
        </dt>
        <dd
          v-if="initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.workPhone"
          class="wow-nomination-submission-review__list__item__value"
        >
          {{ initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.workPhone }}
        </dd>

        <dt
          v-if="initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.fax"
          class="wow-nomination-submission-review__list__item__title"
        >
          {{ eligibilityAndContactInfo.fieldSections.yourContactInfoSection.fields.fax.label }}:
        </dt>
        <dd
          v-if="initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.fax"
          class="wow-nomination-submission-review__list__item__value"
        >
          {{ initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.fax }}
        </dd>

        <dt class="wow-nomination-submission-review__list__item__title">
          {{
            overviewPage.contentSections.eligibilityAndContactInfo.contactInfoSection.fields
              .yourContactInfo.address.label
          }}:
        </dt>
        <dd class="wow-nomination-submission-review__list__item__value">
          <span
            v-if="
              initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.streetAddress
            "
          >
            {{
              initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.streetAddress
            }},
          </span>
          <span
            v-if="initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.aptOrSuite"
          >
            {{ initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.aptOrSuite }},
          </span>
          <span v-if="initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.city">
            {{ initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.city }},
          </span>
          <span v-if="initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.state">
            {{
              initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.state.toUpperCase()
            }}
          </span>
          <span v-if="initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.zipCode">
            {{ initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.zipCode }}
          </span>
        </dd>

        <dt
          v-if="initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.emailAddress"
          class="wow-nomination-submission-review__list__item__title"
        >
          {{
            eligibilityAndContactInfo.fieldSections.yourContactInfoSection.fields.emailAddress
              .label
          }}:
        </dt>
        <dd
          v-if="initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.emailAddress"
          class="wow-nomination-submission-review__list__item__value"
        >
          {{ initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo.emailAddress }}
        </dd>
        <dt class="wow-nomination-submission-review__list__item__title">
          Related to the nominee :
        </dt>
        <dd class="wow-nomination-submission-review__list__item__value">
          <span>{{ getRelative }}</span>
        </dd>
      </dl>

      <!--relative-->

      <!--relative-->

      <p class="honoree-form__subsection__title honoree-form__subsection__title">
        {{ eligibilityAndContactInfo.fieldSections.contactInfoSection.title }}
      </p>
      <p>
        <span v-text="contactInfoNomineeCopy" /> <br />
        {{ initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.jobTitle }}
      </p>

      <dl class="wow-nomination-submission-review__list">
        <dt
          v-if="initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.ageRange"
          class="wow-nomination-submission-review__list__item__title"
        >
          {{ eligibilityAndContactInfo.fieldSections.contactInfoSection.fields.ageRange.label }}:
        </dt>
        <dd
          v-if="initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.ageRange"
          class="wow-nomination-submission-review__list__item__value"
        >
          {{
            getAgeRange(
              initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.ageRange
            )
          }}
        </dd>

        <dt
          v-if="
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.primaryPhone
          "
          class="wow-nomination-submission-review__list__item__title"
        >
          {{
            eligibilityAndContactInfo.fieldSections.yourContactInfoSection.fields.primaryPhone
              .label
          }}:
        </dt>
        <dd
          v-if="
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.primaryPhone
          "
          class="wow-nomination-submission-review__list__item__value"
        >
          {{ initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.primaryPhone }}
        </dd>

        <dt
          v-if="initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.workPhone"
          class="wow-nomination-submission-review__list__item__title"
        >
          {{
            eligibilityAndContactInfo.fieldSections.yourContactInfoSection.fields.workPhone.label
          }}:
        </dt>
        <dd
          v-if="initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.workPhone"
          class="wow-nomination-submission-review__list__item__value"
        >
          {{ initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.workPhone }}
        </dd>

        <dt
          v-if="initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.fax"
          class="wow-nomination-submission-review__list__item__title"
        >
          {{ eligibilityAndContactInfo.fieldSections.yourContactInfoSection.fields.fax.label }}:
        </dt>
        <dd
          v-if="initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.fax"
          class="wow-nomination-submission-review__list__item__value"
        >
          {{ initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.fax }}
        </dd>

        <dt class="wow-nomination-submission-review__list__item__title">
          {{
            overviewPage.contentSections.eligibilityAndContactInfo.contactInfoSection.fields
              .yourContactInfo.address.label
          }}:
        </dt>
        <dd class="wow-nomination-submission-review__list__item__value">
          <span
            v-if="
              initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.streetAddress
            "
          >
            {{
              initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.streetAddress
            }},
          </span>
          <span
            v-if="
              initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.aptOrSuite
            "
          >
            {{
              initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.aptOrSuite
            }},
          </span>
          <span v-if="initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.city">
            {{ initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.city }},
          </span>
          <span
            v-if="initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.state"
          >
            {{
              initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.state.toUpperCase()
            }}
          </span>
          <span
            v-if="initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.zipCode"
          >
            {{ initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.zipCode }}
          </span>
        </dd>
        <!---->
        <dt
          v-if="
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.emailAddress
          "
          class="wow-nomination-submission-review__list__item__title"
        >
          {{
            eligibilityAndContactInfo.fieldSections.yourContactInfoSection.fields.emailAddress
              .label
          }}:
        </dt>
        <dd
          v-if="
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.emailAddress
          "
          class="wow-nomination-submission-review__list__item__value"
        >
          {{ initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.emailAddress }}
        </dd>

        <dt
          v-if="initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.website"
          class="wow-nomination-submission-review__list__item__title"
        >
          {{
            overviewPage.contentSections.eligibilityAndContactInfo.contactInfoSection.fields
              .nomineeContactInfoSection.website.label
          }}:
        </dt>
        <dd
          v-if="initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.website"
          class="wow-nomination-submission-review__list__item__value"
        >
          {{ initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.website }}
        </dd>

        <!--organization s-->

        <dt
          v-if="
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.organizationName
          "
          class="wow-nomination-submission-review__list__item__title"
        >
          {{ nomineeQuestions.organizationDetails.organizationName.label }}:
        </dt>
        <dd
          v-if="
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.organizationName
          "
          class="wow-nomination-submission-review__list__item__value"
        >
          {{
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.organizationName
          }}
        </dd>
        <dt
          v-if="
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.organizationrole
          "
          class="wow-nomination-submission-review__list__item__title"
        >
          {{ nomineeQuestions.organizationDetails.organizationRole.label }}:
        </dt>
        <dd
          v-if="
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.organizationrole
          "
          class="wow-nomination-submission-review__list__item__value"
        >
          {{
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.organizationrole
          }}
        </dd>

        <dt
          v-if="
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo
              .organizationphone
          "
          class="wow-nomination-submission-review__list__item__title"
        >
          {{ nomineeQuestions.organizationDetails.phone.label }}:
        </dt>
        <dd
          v-if="
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo
              .organizationphone
          "
          class="wow-nomination-submission-review__list__item__value"
        >
          {{
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo
              .organizationphone
          }}
        </dd>

        <dt
          v-if="
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo
              .organizationstate
          "
          class="wow-nomination-submission-review__list__item__title"
        >
          {{ nomineeQuestions.organizationDetails.state.label }}:
        </dt>
        <dd
          v-if="
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo
              .organizationstate
          "
          class="wow-nomination-submission-review__list__item__value"
        >
          {{
            getStateName(
              initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo
                .organizationstate
            )
          }}
        </dd>

        <dt
          v-if="
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo
              .organizationwebsite
          "
          class="wow-nomination-submission-review__list__item__title"
        >
          {{ nomineeQuestions.organizationDetails.organizationWebsite.label }}:
        </dt>
        <dd
          v-if="
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo
              .organizationwebsite
          "
          class="wow-nomination-submission-review__list__item__value"
        >
          {{
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo
              .organizationwebsite
          }}
        </dd>

        <dt
          v-if="
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.socialmedialink
          "
          class="wow-nomination-submission-review__list__item__title"
        >
          {{ nomineeQuestions.organizationDetails.facebookLink.label }}:
        </dt>
        <dd
          v-if="
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.socialmedialink
          "
          class="wow-nomination-submission-review__list__item__value"
        >
          {{
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.socialmedialink
          }}
        </dd>

        <dt
          v-if="
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo
              .organizationdescription
          "
          class="wow-nomination-submission-review__list__item__title"
        >
          {{ nomineeQuestions.organizationDetails.organizationMission.label }}:
        </dt>
        <dd
          v-if="
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo
              .organizationdescription
          "
          class="wow-nomination-submission-review__list__item__value"
        >
          {{
            initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo
              .organizationdescription
          }}
        </dd>
      </dl>
      <!--organization e-->
    </section>

    <section class="wow-nomination-submission-review__section">
      <div class="section__title">
        <h2 class="section__title__heading">
          {{ overviewPage.contentSections.nomineeQuestions.title }}
        </h2>

        <button class="wow-button" @click="goToPage(2)">
          {{ translations.formLabels.editButton.label }}
        </button>
      </div>
      <dl class="wow-nomination-submission-review__list">
        <dt class="honoree-form__subsection__title honoree-form__subsection__title">
          {{
            overviewPage.contentSections.nomineeQuestions.contentSections.mainSection
              .nomineeDescription
          }}:
        </dt>
        <dd class="honoree-form__subsection__title__dd">
          {{ initialData.steps.answerQuestionsPage.data.nomineeInformation.nomineeService }}
        </dd>

        <dt class="honoree-form__subsection__title honoree-form__subsection__title">
          {{
            overviewPage.contentSections.nomineeQuestions.contentSections.mainSection
              .communityNeedsAndImpact
          }}:
        </dt>
        <dd class="honoree-form__subsection__title__dd">
          {{ initialData.steps.answerQuestionsPage.data.nomineeInformation.communityNeeds }}
        </dd>

        <dt class="honoree-form__subsection__title honoree-form__subsection__title">
          {{
            overviewPage.contentSections.nomineeQuestions.contentSections.mainSection
              .nomineeSummary
          }}:
        </dt>
        <dd class="honoree-form__subsection__title__dd">
          {{ initialData.steps.answerQuestionsPage.data.nomineeInformation.nomineeSummary }}
        </dd>

        <dt class="honoree-form__subsection__title honoree-form__subsection__title">
          {{
            overviewPage.contentSections.nomineeQuestions.contentSections.mainSection
              .categoriesOfWork
          }}:
        </dt>
        <dd class="honoree-form__subsection__title__dd">
          {{ getCategoriesOfWork() }}
        </dd>
        <dt class="honoree-form__subsection__title honoree-form__subsection__title">
          {{
            overviewPage.contentSections.nomineeQuestions.contentSections.mainSection
              .charitableSection
          }}
        </dt>
        <dd class="honoree-form__subsection__title__dd">
          {{ initialData.steps.answerQuestionsPage.data.nomineeInformation.charitableSection }}
        </dd>
      </dl>
    </section>

    <section class="wow-nomination-submission-review__section">
      <div class="section__title">
        <h2 class="section__title__heading">
          {{ overviewPage.contentSections.surveyPage.title }}
        </h2>

        <button class="wow-button" @click="goToPage(3)">
          {{ translations.formLabels.editButton.label }}
        </button>
      </div>

      <dl class="wow-nomination-submission-review__list">
        <dt class="wow-nomination-submission-review__list__item__title">
          {{ overviewPage.contentSections.surveyPage.contentSection.survey }}:
        </dt>
        <dd class="wow-nomination-submission-review__list__item__value">
          {{ getSurveyResult() }}
        </dd>
      </dl>
    </section>

    <section class="wow-nomination-submission-review__actions">
      <p class="wow-nomination-submission-review__actions__help-text">
        {{ translations.pages.overviewPage.footer.paragraph }}
      </p>
      <div class="wow-nomination-submission-review__actions__buttons-wrapper">
        <a class="wow-button" :href="translations.settings.linkToNominationPage">
          {{ translations.pages.overviewPage.footer.returnToNominations }}
        </a>
        <button class="wow-button" @click="confirmHonoreeDataSubmission">
          {{ translations.pages.overviewPage.footer.submitNomination }}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import { axiosPostWrapper } from './settings';

export default {
  name: 'HonoreeFormOverviewPage',

  props: {
    settings: {
      required: true,
      type: Object,
    },
    translations: {
      required: true,
      type: Object,
    },
    formId: {
      required: true,
      type: String,
    },
    initialData: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      eligibilityAndContactInfo: this.translations.pages.eligibilityAndContactInfo,
      nomineeQuestions: this.translations.pages.nomineeQuestions,
      overviewPage: this.translations.pages.overviewPage,
      surveyPageInfo: this.translations.pages.surveyPageInfo,
    };
  },

  computed: {
    contactInfoNomineeCopy() {
      const { firstName, lastName, title } =
        this.initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo;
      return `${this.getTitleName(title)} ${firstName} ${lastName},`;
    },
    getRelative() {
      let nomineeRelative =
        this.initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo;

      if (nomineeRelative.family.length > 1) {
        return nomineeRelative.family;
      } else if (nomineeRelative.coworkers.length > 1) {
        return nomineeRelative.coworkers;
      } else if (nomineeRelative.friends.length > 1) {
        return nomineeRelative.friends;
      } else if (nomineeRelative?.pleaseDescribe?.length > 1) {
        return 'Other: ' + nomineeRelative.pleaseDescribe;
      }

      return '';
    },
    userContactInfo() {
      const { firstName, lastName, title } =
        this.initialData.steps.eligibilityAndContactInfoPage.data.yourContactInfo;
      return `${this.getTitleName(title)} ${firstName} ${lastName},`;
    },
  },
  methods: {
    goToPage(page) {
      this.$emit('previousClick', page);
    },
    getStateName(selectedState) {
      const { stateCodes } = this.settings;
      return (
        stateCodes.filter((state) => state.id === selectedState || state.code === selectedState)[0]
          .label || ''
      );
    },
    getTitleName(titleId) {
      const { titleOptions } = this.settings;
      return titleOptions.filter((state) => state.id === titleId)[0].label || '';
    },
    getServiceDuration() {
      const { devotedHoursOptions } = this.settings;
      const devotedValue = this.initialData.steps.eligibilityAndContactInfoPage.data.devotedHours;
      return (
        devotedHoursOptions.filter(
          (hours) => hours.id === devotedValue || hours.value === devotedValue
        )[0].label || ''
      );
    },
    getAgeRange() {
      const { ageRangeOptions } = this.settings;
      const selectedValue =
        this.initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.ageRange;

      return (
        ageRangeOptions.filter((age) => {
          return age.id === selectedValue;
        })[0].label || ''
      );
    },
    getServiceBegin() {
      const { monthValues } = this.settings;

      const selectedMonth =
        this.initialData.steps.eligibilityAndContactInfoPage.data.serviceBegin.month;
      const selectedYear =
        this.initialData.steps.eligibilityAndContactInfoPage.data.serviceBegin.year;
      const month = monthValues.filter(
        (month) => month.id === selectedMonth || month.value === selectedMonth
      )[0];

      return `${month.label}/${selectedYear}`;
    },
    getCompensation() {
      const isSalary =
        this.initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.salaried;
      const isStipend =
        this.initialData.steps.eligibilityAndContactInfoPage.data.nomineeContactInfo.stipend;
      const { stipend, noCompensation, salary } =
        this.translations.pages.eligibilityAndContactInfo.fieldSections.compensationSection.options;
      if (isSalary) return salary.label;
      if (isStipend) return stipend.label;
      return noCompensation.label;
    },
    isNominatingYourself() {
      const value = this.initialData.steps.eligibilityAndContactInfoPage.data.nominatingYourself;
      const { yes, no } =
        this.translations.pages.eligibilityAndContactInfo.areYouNominatingYourself.answers;
      return value ? yes.label : no.label;
    },
    isNomineeCurrentlyWorking() {
      const value =
        this.initialData.steps.eligibilityAndContactInfoPage.data.isCurrentlyParticipating;
      const { yes, no } =
        this.translations.pages.eligibilityAndContactInfo.fieldSections.currentStatusSection
          .options;
      return value ? yes.label : no.label;
    },

    isNomineeWorkingForOrganization() {
      const value = this.initialData.steps.answerQuestionsPage.data.isOrganizationService;
      const { yes, no } = this.translations.pages.nomineeQuestions.nomineeOrganizationInfo.options;
      return value ? yes.label : no.label;
    },

    getCategoriesOfWork() {
      const { options } = this.translations.pages.nomineeQuestions.categoriesOfWork.fields;

      const selectedValues =
        this.initialData.steps.answerQuestionsPage.data.nomineeInformation.categoriesOfWork;

      const selected = options.filter(
        (option) =>
          selectedValues.indexOf(option.id) > -1 || selectedValues.indexOf(option.value) > -1
      );

      return `${selected.map((value) => value.label).join(', ')}`;
    },
    getSurveyResult() {
      const { answers } = this.translations.pages.surveyPageInfo.fieldSections.surveySection;

      const selectedValues = this.initialData.steps.surveyPage.data.survey;

      const selected = answers.filter(
        (option) =>
          selectedValues.indexOf(option.id) > -1 || selectedValues.indexOf(option.value) > -1
      );

      return `${selected.map((value) => value.label).join(', ')}`;
    },
    confirmHonoreeDataSubmission() {
      axiosPostWrapper(
        this.settings.webserviceUrl,
        JSON.stringify({ step: 5, nominationId: this.formId })
      ).then((response) => {
        this.$emit('onDataSubmit', response);
      });
    },
  },
};
</script>
