const dataTransform = {
  /**
   * This method returns a normalized version of Honoree Item
   * @param {Object} honoreeData Honoree item from API
   * @returns Normalized Honoree data
   */
  honoreeDataTransform: (honoreeData) => {
    return {
      id: `honoree__${honoreeData.HonoreeId.replace('{', '').replace('}', '')}`,
      year: `${honoreeData.NomineeYear}`,
      state: honoreeData.StateName,
      name: `${honoreeData.FirstName} ${honoreeData.LastName}`,
      organization: honoreeData.OrganizationName,
      honoreeProfile: honoreeData.HonoreeProfileUrl,
      covidNominee: honoreeData.IsCovid19Responder,
      nationalNominee: honoreeData.IsNationalWinner,
      image: {
        alt: honoreeData.PhotoAltText,
        srcset: {
          0: `${honoreeData.Photo}`,
        },
      },
    };
  },
  /**
   * This method returns a normalized version of Filter Item
   * @param {Object} filterData Filter Item
   * @returns Normalized Filter data
   */
  filterDataTransform: (filterData) => {
    return {
      options: optionsDataTransform(filterData.FacetItems),
      label: filterData.Title,
      filterName: filterData.Id,
    };
  },
  /**
   * This method normalizes the list of options
   * @param {Array} facets List of options
   * @returns Normalized list of options
   */
  optionsDataTransform: (facets) => {
    return facets.map(({ Id, Title, IsActive }) => {
      return {
        value: Id,
        label: Title,
        selected: IsActive,
      };
    });
  },
};

export const { honoreeDataTransform, filterDataTransform, optionsDataTransform } = dataTransform;
