<template>
  <div ref="top" class="honoree-nomination">
    <div class="honoree-nomination__back-button">
      <a
        class="wow-button"
        :href="translations.settings.linkToNominationPage"
        type="button"
        v-text="`< ${translations.formLabels.returnToNominations.label}`"
      />
    </div>
    <div class="honoree-nomination__header">
      <HonoreeFormNavigation
        v-if="headings"
        :labels="headings"
        :current-step="currentStep"
        :allowed-steps="allowedSteps"
        @form-navigation="navigationClicked"
      />
    </div>
    <div v-if="hasLoadedTranslations" ref="body" class="honoree-nomination__body" tabindex="-1">
      <transition mode="out-in" name="slide-fade">
        <HonoreeFormPersonalPage
          v-if="currentStep === 1"
          :allowed-steps="allowedSteps"
          :form-id="formId"
          :translations="translations.pages.eligibilityAndContactInfo"
          :settings="translations.settings"
          :form-labels="translations.formLabels"
          :initial-data="currentData.steps.eligibilityAndContactInfoPage"
          @onDataSubmit="handleFormSubmit"
          @nextClick="updateCurrentPage"
        />
        <HonoreeFormQuestionsPage
          v-else-if="currentStep === 2"
          :allowed-steps="allowedSteps"
          :form-id="formId"
          :settings="translations.settings"
          :translations="translations.pages.nomineeQuestions"
          :form-labels="translations.formLabels"
          :initial-data="currentData.steps.answerQuestionsPage"
          @onDataSubmit="handleFormSubmit"
          @previousClick="updateCurrentPage"
          @nextClick="updateCurrentPage"
        />
        <HonoreeFormSurveyPage
          v-else-if="currentStep === 3"
          :allowed-steps="allowedSteps"
          :form-id="formId"
          :settings="translations.settings"
          :translations="translations.pages.surveyPageInfo"
          :form-labels="translations.formLabels"
          :initial-data="currentData.steps.surveyPage"
          @onDataSubmit="handleFormSubmit"
          @previousClick="updateCurrentPage"
          @nextClick="updateCurrentPage"
        />
        <HonoreeFormOverviewPage
          v-else
          :form-id="formId"
          :settings="translations.settings"
          :translations="translations"
          :initial-data="currentData"
          @onDataSubmit="handleOverviewPageSubmit"
          @previousClick="updateCurrentPage"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import HonoreeFormNavigation from './HonoreeFormNavigation.vue';
import HonoreeFormOverviewPage from './HonoreeFormOverviewPage.vue';
import HonoreeFormPersonalPage from './HonoreeFormPersonalPage.vue';
import HonoreeFormQuestionsPage from './HonoreeFormQuestionsPage.vue';
import HonoreeFormSurveyPage from './HonoreeFormSurveyPage.vue';

const OAP_HEADER_HEIGHT = '--oap-header-total-height';

export default {
  name: 'HonoreeNominationForm',
  components: {
    HonoreeFormPersonalPage,
    HonoreeFormQuestionsPage,
    HonoreeFormOverviewPage,
    HonoreeFormNavigation,
    HonoreeFormSurveyPage,
  },
  props: {
    translations: {
      required: true,
      type: Object,
    },
    initialData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      currentStep: this.initialData.currentStep,
      allowedSteps: this.initialData.currentStep,
      formId: this.initialData.formId,
      currentData: this.initialData,
      initialPageTop: 0,
    };
  },
  computed: {
    headings() {
      return this.translations.headings;
    },
    hasLoadedTranslations() {
      return this.translations.pages;
    },
  },
  mounted() {
    const desiredPosition =
      parseInt(
        getComputedStyle(document.querySelector('body')).getPropertyValue(OAP_HEADER_HEIGHT),
        10
      ) + 48;
    this.initialPageTop =
      parseInt(this.$refs.top.getBoundingClientRect().top, 10) - desiredPosition;
  },
  methods: {
    handleFormSubmit(payload) {
      const { success, data } = payload.data;
      const { currentStep } = data;
      if (success) {
        this.allowedSteps = currentStep > this.allowedSteps ? currentStep : this.allowedSteps;
        this.currentData = data;

        setTimeout(() => {
          this.$nextTick(() => {
            this.scrollTop();
          });
        }, 120);
      }
    },
    handleOverviewPageSubmit(payload) {
      const { success } = payload.data;
      if (success) {
        window.location.href = this.translations.settings.linkToNominationPage;
      }
    },
    navigationClicked(payload) {
      this.currentStep = payload.page;
      this.$refs.body.focus();
      this.scrollTop();
    },
    updateCurrentPage(page) {
      this.currentStep = page;
      this.scrollTop();
    },
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, this.initialPageTop);
      });
    },
  },
};
</script>

<style>
/* Enter and leave animations can use different */

/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.25s ease;
}

.slide-fade-leave-active {
  transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
