<template>
  <nav class="honoree-form-navigation" :aria-label="labels.arias.navigationAriaLabel">
    <ul class="honoree-form-navigation__list" role="menubar">
      <li class="honoree-form-navigation__list__item">
        <button
          class="honoree-form-navigation__list__item__button"
          :class="{
            'honoree-form-navigation__list__item__button--active': activeMenuItem === 1,
            'honoree-form-navigation__list__item__button--available': allowedSteps >= 1,
          }"
          role="menuitem"
          @click="formPageNavigationAction(1)"
        >
          <span
            class="honoree-form-navigation__list__item__label"
            v-text="labels.eligibilityAndContactInfo.label"
          />
        </button>
      </li>
      <li class="honoree-form-navigation__list__item">
        <button
          :aria-disabled="allowedSteps < 2"
          class="honoree-form-navigation__list__item__button"
          :class="{
            'honoree-form-navigation__list__item__button--active': activeMenuItem === 2,
            'honoree-form-navigation__list__item__button--available': allowedSteps >= 2,
          }"
          role="menuitem"
          @click="formPageNavigationAction(2)"
        >
          <span
            class="honoree-form-navigation__list__item__label"
            v-text="labels.nomineeQuestions.label"
          />
        </button>
      </li>
      <li class="honoree-form-navigation__list__item">
        <button
          :aria-disabled="allowedSteps < 3"
          class="honoree-form-navigation__list__item__button"
          :class="{
            'honoree-form-navigation__list__item__button--active': activeMenuItem === 3,
            'honoree-form-navigation__list__item__button--available': allowedSteps >= 3,
          }"
          role="menuitem"
          @click="formPageNavigationAction(3)"
        >
          <span class="honoree-form-navigation__list__item__label" v-text="labels.survey.label" />
        </button>
      </li>
      <li class="honoree-form-navigation__list__item">
        <button
          :aria-disabled="allowedSteps < 5"
          class="honoree-form-navigation__list__item__button"
          :class="{
            'honoree-form-navigation__list__item__button--active': activeMenuItem === 5,
            'honoree-form-navigation__list__item__button--available': allowedSteps >= 5,
          }"
          role="menuitem"
          @click="formPageNavigationAction(5)"
        >
          <span
            class="honoree-form-navigation__list__item__label"
            v-text="labels.reviewAndSubmmit.label"
          />
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'HonoreeFormNavigation',
  props: {
    labels: {
      type: Object,
      required: true,
    },
    allowedSteps: {
      type: Number,
      required: true,
      default: 1,
    },
    currentStep: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  computed: {
    activeMenuItem() {
      return this.currentStep;
    },
  },
  methods: {
    formPageNavigationAction(page) {
      if (this.allowedSteps >= page) this.$emit('form-navigation', { page });
    },
  },
};
</script>
