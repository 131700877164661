export const PERSONAL_PAGE = 1;
export const QUESTIONS_PAGE = 2;
export const SURVEY_PAGE = 3;
export const OVERVIEW_PAGE = 5;

export const VALIDATE_CHILD_EVENT = 'validate_child_event';

import axios from 'axios';

export const axiosPostWrapper = (apiUrl, data) => {
  return axios.post(apiUrl, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
