<template>
  <div class="wow-honoree-filter-item">
    <div class="wow-honoree-filter-item__name">
      {{ filterTitle }}
    </div>
    <div class="wow-honoree-filter-item__filter">
      <DropdownComponent
        v-model="selected"
        :options="options"
        :close-on-select="false"
        :is-multi-select="true"
        @change="onFilterChange"
      >
        <template #list="slotProps">
          <li
            v-for="option in slotProps.options"
            :key="option.value"
            class="wow-dropdown-component__list__wrapper"
            tabindex="-1"
            @keyup.esc="slotProps.close"
            @click.prevent="slotProps.selected(option)"
            @keyup.enter.prevent="slotProps.selected(option)"
            @blur="slotProps.blur"
          >
            <input
              :id="option.value"
              class="dropdown-list-item"
              type="checkbox"
              :checked="option.selected"
              :value="option.value"
              name="custom-dropdown"
              @keyup.esc="slotProps.close"
              @click.prevent="slotProps.selected(option)"
              @keyup.enter.prevent="slotProps.selected(option)"
              @blur="slotProps.blur"
            />
            <label
              :for="option.value"
              class="dropdown-list-item__label"
              tabindex="0"
              @blur="slotProps.blur"
            >
              {{ option.label }}
            </label>
          </li>
        </template>
      </DropdownComponent>
    </div>
  </div>
</template>

<script>
import { mapActions } from '../../helpers';
import { WOW_HONOREE_LISTING_UPDATE_FILTER } from '../../settings';
import DropdownComponent from '../DropdownComponent/dropdownComponent.vue';

export default {
  name: 'HonoreeFilterItem',
  components: {
    DropdownComponent,
  },
  props: {
    filterName: {
      type: String,
      required: false,
    },
    filterTitle: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    defaultValue: {
      type: String,
      required: false,
      default: 'All',
    },
  },
  data() {
    return {
      id: `honoree_filter_${this.filterName}`,
      isActive: false,
      selected: undefined,
    };
  },
  methods: {
    ...mapActions([WOW_HONOREE_LISTING_UPDATE_FILTER]),
    onFilterChange(value) {
      this[WOW_HONOREE_LISTING_UPDATE_FILTER]({ id: this.filterName, value });
    },
  },
};
</script>
