<template>
  <section :class="sectionClass">
    <HonoreeFilter />
    <HonoreeGrid>
      <slot />
      <!-- The first chunk of data will come from here -->
    </HonoreeGrid>
    <HonoreePagination />
  </section>
</template>

<script>
import HonoreeGrid from './honoreeGrid.vue';
import HonoreeFilter from './honoreeFilter.vue';
import HonoreePagination from './honoreePagination.vue';
import {
  WOW_HONOREE_LISTING_COMPONENT_CLASS,
  WOW_HONOREE_LISTING_COMPONENT_LOAD_DATA,
} from '../../settings';
import { mapActions } from '../../helpers';

export default {
  name: 'HonoreeListingComponent',

  components: {
    HonoreeFilter,
    HonoreeGrid,
    HonoreePagination,
  },

  computed: {
    sectionClass() {
      return [WOW_HONOREE_LISTING_COMPONENT_CLASS];
    },
  },

  mounted() {
    this[WOW_HONOREE_LISTING_COMPONENT_LOAD_DATA]();
  },

  methods: {
    ...mapActions([WOW_HONOREE_LISTING_COMPONENT_LOAD_DATA]),
  },
};
</script>
