import { VALIDATE_CHILD_EVENT } from './settings';
import eventBus from '@loreal/eventbus-js';

export default {
  props: {
    name: {
      type: String,
    },
    id: {
      type: String,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    initialData: {
      type: [String, Number],
    },
    errorMessage: {
      type: String,
    },
    label: {
      type: String,
    },
  },
  data() {
    return {
      data: this.initialData !== null ? this.initialData : '',
      pristine: true,
    };
  },
  methods: {
    checkDefaultDescribedBy() {
      const input = this.$refs.input;

      if (input && this.defaultDescribedBy) {
        input.setAttribute('aria-describedby', this.defaultDescribedBy);
      }
    },
    validateField() {
      this.pristine = false;
      this.$emit('update:modelValue', {
        value: this.data,
        isValid: this.isValid,
      });
      this.$emit('input', {
        value: this.data,
        isValid: this.isValid,
      });
    },
    atFieldInput(event) {
      this.pristine = false;
      this.$emit('update:modelValue', {
        isValid: this.isValid,
        value: event.target.value,
      });
      this.$emit('input', {
        isValid: this.isValid,
        value: event.target.value,
      });
    },
  },
  computed: {
    ariaDescribedBy() {
      let ariaDescribedByArray = [];

      if (this.defaultDescribedBy) {
        ariaDescribedByArray.push(this.defaultDescribedBy);
      }

      if (this.showErrorMessages) {
        ariaDescribedByArray.push(`${this.id}--error`);
      }

      return ariaDescribedByArray.join(' ');
    },
    showErrorMessages() {
      return this.pristine ? false : !this.isValid;
    },
    isValid() {
      if (!this.isRequired) return true;
      return this.data != undefined && this.data.toString().trim() !== '';
    },
  },
  watch: {
    ariaDescribedBy: {
      deep: true,
      handler(copy) {
        const input = this.$refs.input;

        if (!input) return;

        const attr = 'aria-describedby';
        const hasValidDescription = copy.length > 0;
        const isInputGroup = input.length > 1 && input.type !== 'select-one';

        const setAttribute = (input) => input.setAttribute(attr, copy);
        const removeAttribute = (input) => input.hasAttribute(attr) && input.removeAttribute(attr);

        if (hasValidDescription) {
          isInputGroup ? input.forEach(setAttribute) : setAttribute(input);
        } else {
          isInputGroup ? input.forEach(removeAttribute) : removeAttribute(input);
        }
      },
    },
  },
  mounted() {
    eventBus.on(VALIDATE_CHILD_EVENT, this.validateField);
    this.checkDefaultDescribedBy();
  },
  beforeUnmount() {
    eventBus.off(VALIDATE_CHILD_EVENT, this.validateField);
  },
};
