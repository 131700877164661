import {
  WOW_HONOREE_LISTING_COMPONENT_CLASS,
  WOW_HONOREE_LISTING_COMPONENT_LOAD_DATA,
  WOW_HONOREE_LISTING_COMPONENT_FETCH_DATA,
  WOW_HONOREE_LISTING_COMPONENT_DATA_HANDLER,
  WOW_HONOREE_LISTING_UPDATE_FILTER,
  WOW_HONOREE_LISTING_REGISTER_FILTERS,
  WOW_HONOREE_LISTING_UPDATE_PAGE_NUMBER,
  WOW_HONOREE_LISTING_PAGINATION_ITEM_CLICKED,
  WOW_HONOREE_LISTING_UPDATE_HONOREES,
  WOW_HONOREE_LISTING_UPDATE_CURRENT_PAGE,
  WOW_HONOREE_LISTING_ENABLE_LOADING_STATE,
  WOW_HONOREE_LISTING_DISABLE_LOADING_STATE,
  WOW_HONOREE_LISTING_GET_FILTER,
  WOW_HONOREE_LISTING_REQUEST_URL,
  WOW_HONOREE_LISTING_COMPONENT_UPDATE_SHOW_SLOT_DATA,
  WOW_HONOREE_TOGGLE_VOTING_MODAL,
  WOW_HONOREE_TOGGLE_VOTING_MODAL_ACTION,
  WOW_HONOREE_SEND_HONOREE_DATA,
  WOW_HONOREE_UPDATE_HONOREE_DATA,
  WOW_HONOREE_SET_VOTING_BANNER_OPEN,
} from '../settings';

import { scrollTo } from '../utils/behaviors';

export default {
  [WOW_HONOREE_LISTING_COMPONENT_LOAD_DATA]: ({ dispatch }) => {
    dispatch(WOW_HONOREE_LISTING_COMPONENT_FETCH_DATA).then((data) => {
      dispatch(WOW_HONOREE_LISTING_COMPONENT_DATA_HANDLER, data);
    });
  },
  [WOW_HONOREE_LISTING_COMPONENT_DATA_HANDLER]: ({ dispatch, commit }, data) => {
    dispatch(WOW_HONOREE_LISTING_UPDATE_HONOREES, data.Honorees);
    dispatch(WOW_HONOREE_LISTING_UPDATE_PAGE_NUMBER, data.ResultsHeader.TotalPages);
    dispatch(WOW_HONOREE_LISTING_REGISTER_FILTERS, data.Facets);
    dispatch(WOW_HONOREE_LISTING_UPDATE_CURRENT_PAGE, data.ResultsHeader.CurrentPageNumber);
    commit(WOW_HONOREE_LISTING_DISABLE_LOADING_STATE);
  },
  [WOW_HONOREE_LISTING_COMPONENT_FETCH_DATA]: ({ getters }) => {
    const filter = getters[WOW_HONOREE_LISTING_GET_FILTER];
    return fetch(WOW_HONOREE_LISTING_REQUEST_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(filter),
    }).then((response) => response.json());
  },
  [WOW_HONOREE_LISTING_UPDATE_HONOREES]: ({ commit }, honorees) => {
    commit(WOW_HONOREE_LISTING_UPDATE_HONOREES, honorees);
  },
  [WOW_HONOREE_LISTING_UPDATE_PAGE_NUMBER]: ({ commit }, payload) => {
    commit(WOW_HONOREE_LISTING_UPDATE_PAGE_NUMBER, payload);
  },
  [WOW_HONOREE_LISTING_UPDATE_CURRENT_PAGE]: ({ commit }, pageNumber) => {
    commit(WOW_HONOREE_LISTING_UPDATE_CURRENT_PAGE, pageNumber);
  },
  [WOW_HONOREE_LISTING_PAGINATION_ITEM_CLICKED]: ({ dispatch }, newPage) => {
    dispatch(WOW_HONOREE_LISTING_COMPONENT_UPDATE_SHOW_SLOT_DATA);
    dispatch(WOW_HONOREE_LISTING_UPDATE_CURRENT_PAGE, newPage);
    dispatch(WOW_HONOREE_LISTING_COMPONENT_LOAD_DATA);
    scrollTo(`.${WOW_HONOREE_LISTING_COMPONENT_CLASS}`);
  },
  [WOW_HONOREE_LISTING_UPDATE_FILTER]: ({ commit, dispatch }) => {
    dispatch(WOW_HONOREE_LISTING_COMPONENT_UPDATE_SHOW_SLOT_DATA);
    commit(WOW_HONOREE_LISTING_ENABLE_LOADING_STATE);
    dispatch(WOW_HONOREE_LISTING_UPDATE_CURRENT_PAGE, 1);
    dispatch(WOW_HONOREE_LISTING_COMPONENT_LOAD_DATA);
  },
  [WOW_HONOREE_LISTING_REGISTER_FILTERS]: ({ commit }, filters) => {
    commit(WOW_HONOREE_LISTING_REGISTER_FILTERS, filters);
  },
  [WOW_HONOREE_LISTING_COMPONENT_UPDATE_SHOW_SLOT_DATA]: ({ commit }) => {
    commit(WOW_HONOREE_LISTING_COMPONENT_UPDATE_SHOW_SLOT_DATA);
  },
  [WOW_HONOREE_TOGGLE_VOTING_MODAL_ACTION]: ({ commit }, value) => {
    commit(WOW_HONOREE_TOGGLE_VOTING_MODAL, value);
  },
  [WOW_HONOREE_SEND_HONOREE_DATA]: ({ commit }, value) => {
    commit(WOW_HONOREE_UPDATE_HONOREE_DATA, value);
  },
  [WOW_HONOREE_SET_VOTING_BANNER_OPEN]: ({ commit }, value) => {
    commit(WOW_HONOREE_SET_VOTING_BANNER_OPEN, value);
  },
};
