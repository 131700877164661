<template>
  <!--//NOSONAR The parent component contains the UL tag.-->
  <li :class="clazz">
    <button :aria-current="isActive" :aria-label="ariaLabel" @click="changePage">
      {{ normalizedLabel }}
    </button>
  </li>
</template>

<script>
import {
  WOW_HONOREE_LISTING_GET_CURRENT_PAGE,
  WOW_HONOREE_LISTING_GET_PAGE_NUMBER,
  WOW_HONOREE_LISTING_PAGINATION_ITEM_CLICKED,
} from '../../settings';

import { mapGetters, mapActions } from '../../helpers';

export default {
  name: 'HonoreePaginationItem',
  props: {
    labels: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters([WOW_HONOREE_LISTING_GET_CURRENT_PAGE, WOW_HONOREE_LISTING_GET_PAGE_NUMBER]),
    clazz() {
      return [
        'wow-honoree-pagination-item',
        this.isActive ? 'wow-honoree-pagination-item--active' : undefined,
      ];
    },
    normalizedLabel() {
      return this.index;
    },
    ariaLabel() {
      return this.labels.ariaLabelNotSelected
        .replace('{0}', this.index)
        .replace('{1}', this[WOW_HONOREE_LISTING_GET_PAGE_NUMBER]);
    },
    isActive() {
      return this.index === this[WOW_HONOREE_LISTING_GET_CURRENT_PAGE];
    },
  },
  methods: {
    ...mapActions([WOW_HONOREE_LISTING_PAGINATION_ITEM_CLICKED]),
    changePage() {
      if (!this.isActive) this[WOW_HONOREE_LISTING_PAGINATION_ITEM_CLICKED](this.index);
    },
  },
};
</script>
