<template>
  <article class="wow-honoree-item">
    <div class="wow-honoree-item__image" :style="style">
      <a :href="honoreeProfile">
        <div class="image">
          <img
            v-srcset="image.srcset"
            :alt="image.alt"
            class="image__item"
            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          />
        </div>

        <span
          v-if="covidNominee"
          class="wow-badge wow-badge--covid wow-badge--flat"
          v-text="covidNomineeLabel"
        />

        <span v-if="nationalNominee" class="wow-badge wow-badge--round">
          <svg aria-hidden="true" class="icon">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#wow-badge-round"></use>
          </svg>

          <span class="wow-badge__copy" v-text="nationalNomineeLabel" />
        </span>
      </a>
    </div>
    <div class="wow-honoree-item__content">
      <span class="wow-badge wow-badge--year" v-text="year" />
      <h4 class="wow-honoree-item__kicker" v-text="state" />
      <h3 class="wow-honoree-item__name" v-text="name" />
      <p class="wow-honoree-item__organization" v-text="organization" />
      <a
        class="wow-cta -link"
        :href="honoreeProfile"
        :aria-label="honoreeProfileAriaLabel"
        v-text="honoreeProfileLabel"
      />
      <slot name="honoreeVotingButton" />
    </div>
  </article>
</template>

<script>
import { srcset } from '../../../../../../Foundation/Core/code/Scripts/directives/srcset/srcset';

export default {
  name: 'HonoreeItem',

  directives: {
    srcset,
  },

  props: {
    year: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    state: {
      required: true,
      type: String,
    },
    organization: {
      required: true,
      type: String,
    },
    image: {
      default: () => {
        return {
          alt: 'image alt',
          srcset: {
            0: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
          },
        };
      },
      required: true,
      type: Object,
    },
    covidNominee: {
      default: false,
      type: Boolean,
    },
    covidNomineeLabel: {
      default: 'Covid-19 nominee',
      type: String,
    },
    nationalNominee: {
      default: false,
      type: [Boolean, String],
    },
    nationalNomineeLabel: {
      default: 'National nominee',
      type: String,
    },
    honoreeProfile: {
      required: true,
      type: String,
    },
    honoreeProfileLabel: {
      default: 'Learn more',
      type: String,
    },
    honoreeProfileAriaLabel: {
      default: '',
      type: String,
    },
  },

  data: () => ({
    style: '--aspect-ratio: 206/179',
  }),
};
</script>
