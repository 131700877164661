<template>
  <nav
    v-show="showNavigation"
    role="navigation"
    class="wow-honoree-pagination"
    aria-label="Pagination Navigation"
  >
    <ul class="wow-honoree-pagination__list">
      <li
        v-if="hasPreviousPage"
        class="wow-honoree-pagination-item wow-honoree-pagination-item--arrow -left"
      >
        <button
          type="button"
          :aria-label="labels.previousPageAriaLabel"
          :aria-disabled="!hasPreviousPage"
          :disabled="!hasPreviousPage"
          @click="goto('previous')"
        >
          <svg viewBox="0 0 10.7 6.1">
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#ico-arrow-up"
              x="0"
              y="0"
            ></use>
          </svg>
        </button>
      </li>
      <HonoreePaginationItem v-for="index in pages" :key="index" :index="index" :labels="labels" />
      <li
        v-if="hasNextPage"
        class="wow-honoree-pagination-item wow-honoree-pagination-item--arrow -right"
      >
        <button
          type="button"
          :aria-label="labels.nextPageAriaLabel"
          :aria-disabled="!hasNextPage"
          :disabled="!hasNextPage"
          @click="goto('next')"
        >
          <svg viewBox="0 0 10.7 6.1">
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#ico-arrow-up"
              x="0"
              y="0"
            ></use>
          </svg>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from '../../helpers';
import {
  WOW_HONOREE_LISTING_GET_PAGE_NUMBER,
  WOW_HONOREE_LISTING_GET_CURRENT_PAGE,
  WOW_HONOREE_LISTING_PAGINATION_ITEM_CLICKED,
} from '../../settings';

import HonoreePaginationItem from './honoreePaginationItem.vue';
export default {
  name: 'HonoreePagination',
  components: {
    HonoreePaginationItem,
  },
  props: {
    labels: {
      type: Object,
      required: false,
      default: () => {
        return {
          ariaLabelNotSelected: `Go to page {0} of {1}`,
          ariaLabelSelected: `Current page`,
          previousPageAriaLabel: 'Previous page',
          nextPageAriaLabel: 'Next page',
        };
      },
    },
    targetId: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters([WOW_HONOREE_LISTING_GET_PAGE_NUMBER, WOW_HONOREE_LISTING_GET_CURRENT_PAGE]),
    pages() {
      return this[WOW_HONOREE_LISTING_GET_PAGE_NUMBER] || 1;
    },
    showNavigation() {
      return this.pages > 1;
    },
    hasNextPage() {
      return this[WOW_HONOREE_LISTING_GET_CURRENT_PAGE] < this[WOW_HONOREE_LISTING_GET_PAGE_NUMBER];
    },
    hasPreviousPage() {
      return this[WOW_HONOREE_LISTING_GET_CURRENT_PAGE] > 1;
    },
  },
  methods: {
    ...mapActions([WOW_HONOREE_LISTING_PAGINATION_ITEM_CLICKED]),
    goto(direction) {
      if (direction === 'next')
        this[WOW_HONOREE_LISTING_PAGINATION_ITEM_CLICKED](
          this[WOW_HONOREE_LISTING_GET_CURRENT_PAGE] + 1
        );
      else
        this[WOW_HONOREE_LISTING_PAGINATION_ITEM_CLICKED](
          this[WOW_HONOREE_LISTING_GET_CURRENT_PAGE] - 1
        );
    },
  },
};
</script>
