export default {
  honoreeListing: {
    currentPage: 1,
    pageNumber: 1,
    loading: false,
    showSlotData: true,
    honorees: [],
    filters: [],
    currentFilter: [],
  },
  honoreeVotingModal: false,
  honoreeData: {
    email: '',
    honoreeId: '',
    honoreeName: '',
  },
  votingBannerOpen: false,
};
