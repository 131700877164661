<template>
  <div
    class="wow-dropdown-component"
    :class="{
      'wow-dropdown-component--open': expanded,
      'wow-dropdown-component--hovered': isDropdownHovered,
    }"
  >
    <div
      class="wow-dropdown-component__content"
      :class="{
        'wow-dropdown-component__content--open': expanded,
        'wow-dropdown-component--hovered': isDropdownHovered,
      }"
    >
      <div
        class="wow-dropdown-component__current"
        :class="{
          'wow-dropdown-component__current--open': expanded,
          'wow-dropdown-component--hovered': isDropdownHovered,
        }"
      >
        <button
          id="current-selected"
          ref="toggler"
          class="wow-dropdown-component__current-selected-button"
          :aria-expanded="expanded ? 'true' : 'false'"
          type="button"
          @keyup.esc="close()"
          @click="toggle()"
          @focus="handleFocus('focus', true)"
          @blur="handleFocus('blur', false)"
        >
          {{ selectedLabel }}
        </button>
      </div>
      <ul
        ref="list"
        class="wow-dropdown-component__list scrollbarCustom"
        tabindex="-1"
        :class="{
          'wow-dropdown-component__list--open': expanded,
          'wow-dropdown-component__list--hovered': isDropdownHovered,
        }"
      >
        <slot
          name="list"
          :options="dataModel"
          :close="close"
          :selected="setSelected"
          :blur="dropdownBlurHandler"
        />
      </ul>
    </div>
  </div>
</template>

<script>
const DROPDOWN_LIST_ITEM_CLASS = 'dropdown-list-item';
const DROPDOWN_LIST_ITEM_LABEL_CLASS = 'dropdown-list-item__label';
const WOWDROPDOWNCOMPONENSCROLLBARLISTOPEN =
  'wow-dropdown-component__list scrollbarCustom wow-dropdown-component__list--open';
const WOWDROPDOWNCOMPLISTWRAPPER = 'wow-dropdown-component__list__wrapper';

export default {
  name: 'DropdownComponent',
  props: {
    options: { type: Array, required: true, default: undefined },
    closeOnSelect: { type: Boolean, required: false, default: true },
    isMultiSelect: { type: Boolean, required: false, default: false },
    defaultValue: { type: String, required: false, default: 'All' },
  },
  data() {
    return {
      dataModel: this.options,
      expanded: false,
      isDropdownHovered: false,
    };
  },
  computed: {
    selectedLabel() {
      return (
        this.dataModel
          .filter((o) => o.selected)
          .map((o) => o.label)
          .join(', ') || this.defaultValue
      );
    },
  },
  watch: {
    options: function (newValue) {
      this.dataModel = newValue;
      this.updateState(this.dataModel.filter((option) => option.selected));
    },
  },
  mounted() {
    /* istanbul ignore next */
    this.$nextTick(() => {
      const listOfOptions = this.$refs.list.querySelectorAll(`.${DROPDOWN_LIST_ITEM_CLASS}`);
      Array.prototype.forEach.call(listOfOptions, (item) => {
        item.addEventListener('blur', this.dropdownBlurHandler);
      });
      this.$refs.toggler.addEventListener('blur', this.dropdownBlurHandler);

      document.body.addEventListener('click', this.scrollerClick, true);
      this.updateState(this.dataModel.filter((option) => option.selected));
    });
  },

  /* istanbul ignore next */
  beforeUnmount() {
    const listOfOptions = this.$refs.list.querySelectorAll(`.${DROPDOWN_LIST_ITEM_CLASS}`);
    Array.prototype.forEach.call(listOfOptions, (item) => {
      item.removeEventListener('blur', this.dropdownBlurHandler);
    });
    this.$refs.toggler.removeEventListener('blur', this.dropdownBlurHandler);
    document.body.removeEventListener('click', this.scrollerClick, true);
  },
  methods: {
    scrollerClick(event) {
      if (
        event.target.className === '' ||
        (event.target.className !== DROPDOWN_LIST_ITEM_CLASS &&
          event.target.className !== DROPDOWN_LIST_ITEM_LABEL_CLASS &&
          event.target.className !== WOWDROPDOWNCOMPONENSCROLLBARLISTOPEN &&
          event.target.className !== WOWDROPDOWNCOMPLISTWRAPPER &&
          event.target.className !== 'wow-dropdown-component__current-selected-button')
      ) {
        this.expanded = false;
      }
    },
    close() {
      this.expanded = false;
      this.$refs.toggler.focus();
    },
    toggle() {
      this.expanded = !this.expanded;

      /* istanbul ignore next */
      if (this.expanded) {
        const first = this.$refs.list.querySelectorAll(`.${DROPDOWN_LIST_ITEM_CLASS}`)[0];
        if (first) first.focus();
      }
    },
    setSelected(value) {
      this.dataModel.forEach((option) => {
        if (this.isMultiSelect) {
          if (option.value === value.value) option.selected = !option.selected;
        } else {
          option.selected = option.value === value.value;
        }
      });
      const selectedValues = this.dataModel.filter((option) => option.selected);

      this.updateState(selectedValues);
      this.$nextTick(() => {
        this.$emit('change', this.isMultiSelect ? selectedValues : selectedValues[0]);
      });
      if (this.closeOnSelect) this.close();
    },
    updateState(selectedValues) {
      if (this.isMultiSelect) {
        this.$emit('input', selectedValues);
      } else {
        this.$emit('input', selectedValues[0] || undefined);
      }
    },
    dropdownBlurHandler() {
      this.$nextTick(() => {
        setTimeout(() => {
          let activeElementClass;

          /* istanbul ignore next */
          if (document.documentMode || /Edge/.test(navigator.userAgent)) {
            /* eslint-disable prefer-destructuring */
            activeElementClass = document.activeElement.classList[0] || null;
          } else {
            activeElementClass = document.activeElement.classList.value;
          }
          const activeElementClassNotIncludesDropwdownListItem =
            !activeElementClass.includes(DROPDOWN_LIST_ITEM_CLASS);
          const activeElementClassNotIncludesElementListItemLabel = !activeElementClass.includes(
            DROPDOWN_LIST_ITEM_LABEL_CLASS
          );
          const activeDropdownComponentScrollbarListOpen = !activeElementClass.includes(
            WOWDROPDOWNCOMPONENSCROLLBARLISTOPEN
          );
          const activeWowDropdownCompListWrapper = !activeElementClass.includes(
            WOWDROPDOWNCOMPLISTWRAPPER
          );
          if (
            activeElementClassNotIncludesDropwdownListItem &&
            activeElementClassNotIncludesElementListItemLabel &&
            activeDropdownComponentScrollbarListOpen &&
            activeWowDropdownCompListWrapper
          ) {
            this.expanded = false;
          }
        }, 300);
      });
    },
    handleFocus(event, focused) {
      this.$emit(event, focused);
    },
  },
};
</script>
