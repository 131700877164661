export const WOW_HONOREE_LISTING_COMPONENT_CLASS = 'wow-honoree-listing-component';

export const WOW_HONOREE_LISTING_COMPONENT_NAMESPACE = 'wow_honoree_listing_component_namespace';

export const WOW_HONOREE_LISTING_COMPONENT_GET_SHOW_SLOT_DATA =
  'wow_honoree_listing_component_get_show_slot_data';
export const WOW_HONOREE_LISTING_COMPONENT_UPDATE_SHOW_SLOT_DATA =
  'wow_honoree_listing_component_update_show_slot_data';

export const WOW_HONOREE_LISTING_COMPONENT_LOAD_DATA = 'wow_honoree_listing_component_load_data';
export const WOW_HONOREE_LISTING_COMPONENT_FETCH_DATA = 'wow_honoree_listing_component_fetch_data';
export const WOW_HONOREE_LISTING_COMPONENT_DATA_HANDLER =
  'wow_honoree_listing_component_data_handler';

// Honoree Listing loading state
export const WOW_HONOREE_LISTING_ENABLE_LOADING_STATE = 'wow_honoree_listing_enable_loading_state';
export const WOW_HONOREE_LISTING_DISABLE_LOADING_STATE =
  'wow_honoree_listing_disable_loading_state';

// Honoree Listing filter data
export const WOW_HONOREE_LISTING_REGISTER_FILTERS = 'wow_honoree_register_filters';
export const WOW_HONOREE_LISTING_GET_FILTER = 'wow_honoree_listing_get_filter';
export const WOW_HONOREE_LISTING_GET_FILTERS = 'wow_honoree_listing_get_filters';
export const WOW_HONOREE_LISTING_UPDATE_FILTER = 'wow_honoree_listing_update_filter';

// Honoree Listing honorees data
export const WOW_HONOREE_LISTING_GET_HONOREES = 'wow_honoree_listing_get_honorees';
export const WOW_HONOREE_LISTING_UPDATE_HONOREES = 'wow_honoree_listing_update_honorees';

export const WOW_HONOREE_LISTING_PAGINATION_ITEM_CLICKED =
  'wow_honoree_listing_pagination_item_clicked';

// Honoree Listing Page data
export const WOW_HONOREE_LISTING_GET_CURRENT_PAGE = 'wow_honoree_listing_get_current_page';
export const WOW_HONOREE_LISTING_UPDATE_CURRENT_PAGE = 'wow_honoree_listing_update_current_page';
export const WOW_HONOREE_LISTING_GET_PAGE_NUMBER = 'wow_honoree_listing_get_page_number';
export const WOW_HONOREE_LISTING_UPDATE_PAGE_NUMBER = 'wow_honoree_listing_update_page_number';

//Honoree voting foudation data
export const WOW_HONOREE_TOGGLE_VOTING_MODAL = 'wow_honoree_toggle_voting_modal';
export const WOW_HONOREE_TOGGLE_VOTING_MODAL_ACTION = 'wow_honoree_toggle_voting_modal_action';
export const WOW_HONOREE_GET_VOTING_MODAL = 'wow_honoree_get_voting_modal_action';
export const WOW_HONOREE_SEND_HONOREE_DATA = 'wow_honoree_send_honoree_data';
export const WOW_HONOREE_GET_HONOREE_DATA = 'wow_honoree_get_honoree_data';
export const WOW_HONOREE_UPDATE_HONOREE_DATA = 'wow_honoree_update_honoree_data';
export const WOW_HONOREE_VOTING_BANNER_OPEN = 'wow_honoree_voting_banner_open';
export const WOW_HONOREE_UPDATE_VOTING_BANNER_OPEN = 'wow_honoree_update_voting_banner_open';
export const WOW_HONOREE_SET_VOTING_BANNER_OPEN = 'wow_honoree_set_voting_banner_open';

export const WOW_HONOREE_LISTING_REQUEST_URL = '/apioap/wow/yearbook';
