<template>
  <div>
    <div class="honoree-form__checkbox-group" :class="customClasses">
      <label
        v-for="(option, $key) in options"
        :key="$key"
        :for="option.id"
        class="honoree-form__checkbox__wrapper"
      >
        <input
          :id="option.id"
          ref="input"
          v-model="data"
          class="honoree-form__checkbox__input"
          :disabled="disabled && !data.includes(option.id)"
          :value="option.id"
          type="checkbox"
          :aria-invalid="showErrorMessages"
        />
        <span class="honoree-form__checkbox__checkmark" />
        <span>
          {{ option.label }}
        </span>
      </label>
    </div>
    <span v-if="showErrorMessages" :id="`${id}--error`" class="honoree-form__error-state">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
import HonoreeFieldMixins from './HonoreeFieldMixins';

export default {
  name: 'HonoreeFormCheckboxField',
  mixins: [HonoreeFieldMixins],
  props: {
    maximum: {
      type: Number,
    },
    minimum: {
      type: Number,
      default: 1,
    },
    options: {
      type: Array,
    },
    customClasses: {
      type: String,
      default: '',
    },
    initialData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      data: this.initialData !== null ? this.initialData : [],
    };
  },
  computed: {
    disabled() {
      return this.data.length >= this.maximum;
    },
    isValid() {
      return this.data.length >= this.minimum && this.data.length <= this.maximum;
    },
  },
  watch: {
    /* istanbul ignore next */
    data(value) {
      this.pristine = false;
      this.$emit('input', value);
    },
  },
};
</script>
