import 'lazysizes';
import { createApp } from 'vue';
import { createStore } from 'vuex';

// Plugins for zone-level customizations
import loadZoneCustomizations from '@loreal/zone-plugin-loader';
import init from '@loreal/sisyphus';

// Sitecore-Vue bridge
import SitecoreVueAdapter from '@Foundation/sitecore/SitecoreVueAdapter';

// Helpers
import { requireAll } from '@Foundation/utilities/helpers';

// Foundation
import Foundation from '@/Foundation/Core/code/Scripts';
import LoadScriptModule from '@/Foundation/Core/code/Scripts/loadscript';
import eventBus, { exposeGlobals } from '@loreal/eventbus-js';

// Feature
import CarouselModule from '../../../../Feature/Carousel';
import NavigationModule from '../../../../Feature/Navigation';
import NewsletterModule from '@Feature/NewsLetter';
import OapAccountNavigation from '../../../../Feature/OapAccountNavigation';
import OapContrastSwitcher from '@Feature/OapContrastSwitcher';
import OapPromoBar from '../../../../Feature/OapPromoBar';
import Overlay from '../../../../Feature/Overlay';
import OapToastNotification from '@Feature/OapToastNotification';
import Search from '@Feature/Search';
import SearchSuggestions from '@Feature/SearchSuggestions';
import Slider from '@Feature/Slider';
import Tags from '@Feature/PageContentExt';
import VideoPlaceholder from '@Feature/VideoPlaceholder';
import WowModule from '@Feature/WomenOfWorth';
import HeaderLogo from '@Feature/HeaderLogo';

// 3rd party
import Toasted from '@Feature/OapToastNotification/code/plugin';

exposeGlobals();
const app = createApp({});
// TODO: To be enabled if removing imports for eventBus makes sense
// app.config.globalProperties.eventBus = eventBus;
app.config.compilerOptions.whitespace = 'preserve';
app.config.performance = true;

// Bootstrap Vue
app.use(Toasted);

// Register directives globally
Object.entries({
  ...Foundation.directives,
  ...LoadScriptModule.directives,
  ...NavigationModule.directives,
  ...Search.directives,
  ...WowModule.directives,
}).forEach(([name, directive]) => app.directive(name, directive));

// Register component globally
// TODO: Object.values ???
Object.entries({
  ...OapToastNotification.components,
  ...CarouselModule.components,
  ...Foundation.components,
  ...LoadScriptModule.components,
  ...NavigationModule.components,
  ...NewsletterModule.components,
  ...OapAccountNavigation.components,
  ...OapContrastSwitcher.components,
  ...OapPromoBar.components,
  ...Overlay.components,
  ...SearchSuggestions.components,
  ...Slider.components,
  ...Tags.components,
  ...VideoPlaceholder.components,
  ...WowModule.components,
  ...HeaderLogo.components,
}).forEach(([name, component]) => app.component(name, component));

Object.entries({
  ...Foundation.filters,
}).forEach(([name, filter]) => app.filter(name, filter));

const store = new createStore({
  plugins: [],
  modules: {
    ...WowModule.modules,
  },
});

app.use(store);

/**
 * Initialize any zone-level customizations.
 * Every zone/country can create their own Vue components and make them available
 * to their code through the use of a Vue.js Plugin
 *
 * @see https://vuejs.org/guide/reusability/plugins.html#writing-a-plugin
 * */
loadZoneCustomizations(app, store);
// Initialize third party loader
init();

requireAll(require.context('../Icons/', true, /\.svg$/));

document.addEventListener('lazybeforeunveil', (event) =>
  eventBus.emit('image.lazybeforeunveil', event)
);

SitecoreVueAdapter.initializeVueApp(app.mount.bind(app, '#main-container'));
