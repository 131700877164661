<template>
  <div>
    <label :for="id" class="honoree-form__text-group">
      {{ label }}
      <textarea
        :id="id"
        ref="input"
        v-model="data"
        class="honoree-form__text__input honoree-form__text__input--text-area"
        maxlength="500"
        :required="isRequired"
        :aria-invalid="showErrorMessages"
        @input="atFieldInput"
      ></textarea>
    </label>
    <span :id="`${id}--charcount`" class="honoree-form__charcount">
      {{ characterCounterUpdated }}
    </span>
    <span v-if="showErrorMessages" :id="`${id}--error`" class="honoree-form__error-state">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
import HonoreeFieldMixins from './HonoreeFieldMixins';

export default {
  name: 'HonereeFormTextAreaField',
  mixins: [HonoreeFieldMixins],
  props: {
    characterCounter: {
      type: String,
    },
  },
  data() {
    return {
      defaultDescribedBy: `${this.id}--charcount`,
    };
  },
  computed: {
    characterCounterUpdated() {
      return this.characterCounter.replace('$0', this.data.length);
    },
  },
};
</script>
